<template>
    <div style="cursor: default; position: absolute; top: 0; left: 0px; padding: 0 !important; width: 100%; height: calc(100%); z-index: 10; overflow: hidden; white-space: nowrap;">
        <div style="position: relative; height: 100%; width: 100%; display: flex; flex-direction: row; align-items: center;" >
            
            <template v-for="timeline in lower_timeline" >
                <div :style="'position: absolute; width: ' + timeline.width + 'px; transform: translateX(' + timeline.offset + 'px);' + ' overflow: hidden; height: 100%; ' + (!timeline.is_first ? 'border-left: 2px solid var(--o365-grid-header-border);' : '')">
                    <div style="width: 100%; height: 100%; display: flex; flex-direction: row; align-items: center; justify-content: center; color: #28292e;">
                        {{date_text(timeline, TextType.Simple)}}
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, defineEmits } from 'vue';
    
    const emit = defineEmits(['scrollTimelineTo'])

    function emitScrollTimelineToEvent(pEvent) {
        const newDate = new Date(parseInt(pEvent.target.value));
        emit('scrollTimelineTo', newDate);
    }

    const timeline_position_from_date = (date: Date): number =>
    {
        if (props.to?.getTime() - props.from?.getTime() === 0) {
            return 0;
        }
        return props.width * (date?.getTime() - props.from?.getTime()) / (props.to?.getTime() - props.from?.getTime());
    };

    type TimelineCell = {
        is_first: boolean;
        from_date: Date;
        to_date: Date;
        offset: number;
        width: number;
        timeline_unit: TimelineUnit;
    };

    type Timeline = Array<TimelineCell>;

    enum TimelineUnit {
        Century,
        Decade,
        Year,
        QuarterYear,
        Month,
        Week,
        Day,
        SixHours,
        Hour,
        QuarterHour,
        Minute,
    };

    enum TextType {
        Simple,
        Full,
    }

    const props = defineProps({
        from: Date,
        to: Date,
        outerFrom: Date,
        outerTo: Date,
        width: Number
    });

    const min_cell_width = 40; //px

    const calculate_timeline_unit = (from_date: Date, to_date: Date, timeline_width: number, min_cell_width: number): TimelineUnit => {
        const daterange_minutes = (to_date?.getTime() - from_date?.getTime()) / 1000 / 60;
        const daterange_quarterhour = daterange_minutes / 15;
        const daterange_hours = daterange_minutes / 60;
        const daterange_sixhours = daterange_hours / 6;
        const daterange_days = daterange_hours / 24;
        const daterange_weeks = daterange_days / 7;
        const daterange_months = daterange_days / 30;
        const daterange_quarteryear = daterange_days / 91;
        const daterange_years = daterange_days / 365;
        const daterange_decades = daterange_years / 10;
        const daterange_centuries = daterange_years / 100;

        if (timeline_width / daterange_minutes >= min_cell_width) {
            return TimelineUnit.Minute;
        } else if (timeline_width / daterange_quarterhour >= min_cell_width) {
            return TimelineUnit.QuarterHour;
        } else if (timeline_width / daterange_hours >= min_cell_width) {
            return TimelineUnit.Hour;
        } else if (timeline_width / daterange_sixhours >= min_cell_width) {
            return TimelineUnit.SixHours;
        } else if (timeline_width / daterange_days >= min_cell_width) {
            return TimelineUnit.Day;
        } else if (timeline_width / daterange_weeks >= min_cell_width) {
            return TimelineUnit.Week;
        } else if (timeline_width / daterange_months >= min_cell_width) {
            return TimelineUnit.Month;
        } else if (timeline_width / daterange_quarteryear >= min_cell_width) {
            return TimelineUnit.QuarterYear;
        } else if (timeline_width / daterange_years >= min_cell_width) {
            return TimelineUnit.Year;
        } else if (timeline_width / daterange_decades >= min_cell_width) {
            return TimelineUnit.Decade;
        } else if (timeline_width / daterange_centuries >= min_cell_width) {
            return TimelineUnit.Century;
        } else {
            return TimelineUnit.Century;
        }
    }
    
    const lower_timeline_unit = computed((): TimelineUnit => calculate_timeline_unit(props.from, props.to, props.width, min_cell_width));
    const upper_timeline_unit = computed(() => Math.max(lower_timeline_unit.value-1, 0));

    const hour = 3600 * 1000;
    const day = hour * 24;

    const three_Hour_hours = Array.from(Array(24/3).keys()).map((i) => (i) * 3);
    const QuarterYear_months = Array.from(Array(12/3).keys()).map((i) => (i) * 3);

    const timeline_width_from_dates = (d1: Date, d2: Date): number => Math.abs(timeline_position_from_date(d1) - timeline_position_from_date(d2));

    const get_next_timeline_date = (date: Date, timeline_unit: TimelineUnit): Date =>
    {
        let new_date: Date;

        switch (timeline_unit)
        {
            case TimelineUnit.Minute:
                new_date = new Date(date?.getTime());
                new_date.setMinutes(date?.getMinutes() + 1, 0, 0);
                break;

            case TimelineUnit.QuarterHour:
                new_date = new Date(date?.getTime());
                new_date.setMinutes(date?.getMinutes() + 15 - (date.getMinutes() % 15), 0, 0);
                break;

            case TimelineUnit.Hour:
                new_date = new Date(date?.getTime());
                new_date.setHours(date?.getHours() + 1, 0, 0, 0);
                break;
            
            case TimelineUnit.SixHours:
                new_date = new Date(date?.getTime());
                new_date.setHours(date?.getHours() + 6 - (date.getHours() % 6), 0, 0, 0);
                break;
            
            case TimelineUnit.Day:
                new_date = new Date(date?.getTime());
                new_date.setDate(new_date?.getDate() + 1);
                new_date.setHours(0, 0, 0, 0);
                break;
            
            case TimelineUnit.Week:
                new_date = new Date(date?.getTime());
                new_date.setDate(new_date?.getDate() + 7 - (new_date.getDay() >= 1 ? new_date.getDay() - 1 : 6));
                new_date.setHours(0, 0, 0, 0);
                break;
            
            case TimelineUnit.Month:
                new_date = new Date(date?.getTime());
                new_date.setMonth(new_date?.getMonth() + 1);
                new_date.setDate(1);
                new_date.setHours(0, 0, 0, 0);
                break;

            case TimelineUnit.QuarterYear:
                new_date = new Date(date?.getTime());
                new_date.setMonth(new_date?.getMonth() + 3 - (new_date.getMonth() % 3));
                new_date.setDate(1);
                new_date.setHours(0, 0, 0, 0);
                break;

            case TimelineUnit.Year:
                new_date = new Date(date?.getTime());
                new_date.setFullYear(new_date?.getFullYear() + 1, 0, 1);
                break;

            case TimelineUnit.Decade:
                new_date = new Date(date?.getTime());
                new_date.setFullYear(new_date?.getFullYear() + 10 - (date.getFullYear() % 10), 0, 1);
                break;

            case TimelineUnit.Century:
                new_date = new Date(date?.getTime());
                new_date.setFullYear(new_date?.getFullYear() + 100 - (date.getFullYear() % 100), 0, 1);
                break;
        }
        
        return new_date;
    }

    const date_text = (timeline_cell: TimelineCell, text_type: TextType): string =>
    {
        switch(timeline_cell.timeline_unit)
        {
            case TimelineUnit.Minute:       return timeline_cell.from_date.toLocaleTimeString(undefined, { minute: 'numeric' });
            case TimelineUnit.QuarterHour:  return timeline_cell.from_date.toLocaleTimeString(undefined, { minute: 'numeric' });
            case TimelineUnit.Hour:         return timeline_cell.from_date.toLocaleTimeString(undefined, { hour: 'numeric' });
            case TimelineUnit.SixHours:     return timeline_cell.from_date.toLocaleTimeString(undefined, { hour: '2-digit' })
            case TimelineUnit.Day:          return timeline_cell.from_date.toLocaleDateString(undefined, { weekday: 'narrow' });
            case TimelineUnit.Week:         return timeline_cell.from_date.toLocaleDateString(undefined, { day: '2-digit' }) + ' - ' + new Date(new Date(timeline_cell.to_date).setDate(timeline_cell.to_date.getDate() - 1)).toLocaleDateString(undefined, {day: '2-digit' }) + '';
            case TimelineUnit.Month:        return timeline_cell.from_date.toLocaleDateString(undefined, { month: 'short' })
            case TimelineUnit.QuarterYear:  return 'Q' + (Math.floor((timeline_cell.from_date.getMonth()) / 3) + 1);
            case TimelineUnit.Year:         return timeline_cell.from_date.getFullYear().toString();
            case TimelineUnit.Decade:       return timeline_cell.from_date.getFullYear().toString();
            case TimelineUnit.Century:      return timeline_cell.from_date.getFullYear().toString();
        }

        return 'N/A';

    };

    const create_timeline = (timeline_unit: TimelineUnit, width: number): Timeline =>
    {
        const timeline: Timeline = [];

        let current_offset = 0;
        let current_date = new Date(props.from?.getTime());

        while(current_offset < width) {
            const next_date: Date = get_next_timeline_date(current_date, timeline_unit);
            const next_offset: number = timeline_position_from_date(next_date);
            const current_width: number = next_offset - current_offset;
            
            if (current_width <= 0) {
                break;
            }

            timeline.push({
                is_first: (timeline.length == 0),
                from_date: current_date,
                to_date: next_date,
                offset: current_offset,
                width: current_width,
                timeline_unit: timeline_unit,
            });

            current_offset = next_offset;
            current_date = next_date;
        }

        return timeline;
    }
    
    const lower_timeline = computed((): Timeline => create_timeline(lower_timeline_unit.value, props.width));
    const upper_timeline = computed((): Timeline => upper_timeline_unit.value == lower_timeline_unit.value ? [] : create_timeline(upper_timeline_unit.value, props.width));

</script>